    .BlogButton {
    background-color: #B56A30 !important;
    color: black !important;
    border-radius: 10px !important;
    }
    .WebButton{
    background-color: #B56A30 !important;
    color: black !important;
    font-weight: 600 !important;
    padding: 6px 20px !important;
    border-radius: 10px!important;
    }
    .serviceBg{
    background-image: url('../Assets/ActiveCard1.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    color: #fff;
    width: 430px;
    height: 450px;
    position: relative;
    }

    .serviceBg1{
    background-image: url('../Assets/InactiveCard.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    color: #fff;
    width: 330px;
    height: 320px;
    position: relative;
    margin-top: 20px;
    }


    .textBox{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    text-align: center;
    }

    .textBox1{
    position: absolute;
    top: 50%;
    left: 50%;
    }
    .titletext{
    font-size: 18px !important;
    }
    .titletext1{
    font-size: 18px !important;
    }

    .contenttext{
    font-size: 14px !important;
    }
    .contenttext1{
    font-size: 12px !important;
    }

    #backWardArrow {
    top: 90%;
    left: -8%;
    position: absolute;
    height: 35px !important;
    }

    #frontArrow{
    position: absolute;
    top: 90%;
    right: 2%;
    height: 35px !important;
    }

    .BlogButton1 {
    background-color: transparent !important;
    border-radius: 10px !important;
    }

    #SlideImagebackWardArrow {
    top:50%;
    left: 10px;
    position: absolute;
    z-index: 10;
    rotate: 180deg;
    }

    #SlideImagefrontArrow{
    position: absolute;
    top: 50%;
    right: 10px;
    }

    .blog-item-visible.from-right{
    animation: bounceInLeft 2s ease 0s 1 normal forwards;
    }

    li::marker {
    color: #B56A30 ;
    }


    @-webkit-keyframes bounceInLeft {
    0%, 60%, 75%, 90%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }
    0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
    }
    60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
    }
    75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
    }
    90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
    }
    100% {
    -webkit-transform: none;
    transform: none;
    }
    }
    @keyframes bounceInLeft {
    0%, 60%, 75%, 90%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }
    0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
    }
    60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
    }
    75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
    }
    90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
    }
    100% {
    -webkit-transform: none;
    transform: none;
    }
    } 

    .blog-item-visible.from-left {
    animation: bounceInRight 2s ease 0s 1 normal forwards;
    }

    @-webkit-keyframes bounceInRight {
    0%, 60%, 75%, 90%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }
    0% {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
    }
    60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
    }
    75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
    }
    90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
    }
    100% {
    -webkit-transform: none;
    transform: none;
    }
    }
    @keyframes bounceInRight {
    0%, 60%, 75%, 90%, 100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }
    0% {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
    }
    60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
    }
    75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
    }
    90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
    }
    100% {
    -webkit-transform: none;
    transform: none;
    }
    } 





    .headingText1 {
    font-size: 50px !important;
    font-weight: 600 !important;
    -webkit-animation-name: lightSpeedIn;
    animation-name: lightSpeedIn;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    }
    @-webkit-keyframes lightSpeedIn {
    0% {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
    }
    60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1;
    }
    80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1;
    }
    100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
    }
    }
    @keyframes lightSpeedIn {
    0% {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
    }
    60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1;
    }
    80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1;
    }
    100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
    }
    } 

    .headingText2{
    font-size: 50px !important;
    font-weight: 600 !important; 
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    }
    @-webkit-keyframes zoomIn {
    0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
    }
    50% {
    opacity: 1;
    }
    }
    @keyframes zoomIn {
    0% {
    opacity: 0;
    -webkit-transform: scale3d(.3, .3, .3);
    transform: scale3d(.3, .3, .3);
    }
    50% {
    opacity: 1;
    }
    } 


    .custom-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #616e80; /* Inactive dot color */
    margin: 6px 7px;
    cursor: pointer;
    }

    .custom-dot.active {
    background: #c7854c; /* Active dot color */
    }


    .SliderImagePreview{
    object-fit: cover;
    object-position :center;
    width:100%;
    height: 500px;
    z-index:0;
    }

    .sliderText{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -10%);
    z-index: 10;
    width: 80%;
    text-shadow: 2px 2px 7px #454233;
    }


    .testSlider{
    width: 380px !important;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 250px;
    justify-content: space-between;
    align-content: space-between;
    align-items: space-between;
    }


    .logiMage{
        display: none;    
        }


    @media screen and (min-width: 401px) and (max-width: 600px) {
    .sliderText{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -80%);
    z-index: 10;
    width: 100%;
    text-shadow: 2px 2px 7px #454233;
    }
    .testSlider{
    width: 300px !important;
    }
    .logiMage{
        display: flex;
    width:200px !important;
    }
    }

    @media screen and (min-width: 601px) and (max-width: 900px)  {
    .sliderText{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -30%);
    z-index: 10;
    width: 100%;
    text-shadow: 2px 2px 7px #454233;
    }
    .testSlider{
    width: 350px !important;
    }
    .logiMage{
        display: flex;
    width:230px !important;
    }
    }


    @media screen and (min-width: 901px) and (max-width: 1025px)  {
    .testSlider{
    width: 300px !important;
    }
    .sliderText{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -10%);
        z-index: 10;
        width: 100%;
        text-shadow: 2px 2px 7px #454233;
        }
        .logiMage{
        display: none;    
        }
    }


    @media screen and (max-width: 480px) {
    .testSlider{
    width: 100% !important;
    }

    .titletext{
    font-size: 16px !important;
    }
    .titletext1{
    font-size: 16px !important;
    }

    .contenttext{
    font-size: 12px !important;
    }
    .contenttext1{
    font-size: 12px !important;
    }
    .logiMage{
        display: flex;
    width:200px !important;
    }
    .sliderText{
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, 50%);
    z-index: 10;
    width: 100%;
    text-shadow: 2px 2px 7px #454233;
    }
    }



    .float{
    position: fixed;
    right: 15px;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    z-index: 100;
    }


    .signinBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    align-self: center;
    color:#ffff;
    background: rgba(130, 158, 141, 0.42);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9.1px);
    -webkit-backdrop-filter: blur(9.1px);
    border: 1px solid rgba(130, 158, 141, 0.3);
    }


    .signinBanner{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    } 


