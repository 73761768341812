body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.aboutgrid::before {
  position: absolute;
  z-index: 1;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  content: "";
  transform: rotate(45deg);
  top: 0;
  left: 50%;
  border-left-width: 0;
  border-top-width: 0;
  background-color: #292929;
}

.aboutgrid1::before {
  position: absolute;
  z-index: 1;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  content: "";
  transform: rotate(45deg);
  top: 50%;
  left: 50%;
  border-left-width: 0;
  border-top-width: 0;
  background-color: #dfeeee;
}

.aboutgrid2::before {
  position: absolute;
  z-index: 1;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  content: "";
  transform: rotate(45deg);
  top: 100%;
  left: 50%;
  border-left-width: 0;
  border-top-width: 0;
  background-color: #f0f7f7;
}

.aboutgrid4::before {
  position: absolute;
  z-index: 1;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  content: "";
  transform: rotate(45deg);
  top: 100%;
  left: 50%;
  border-left-width: 0;
  border-top-width: 0;
  background-color: #dfeeee;
}

.aboutgrid3::before {
  position: absolute;
  z-index: 1;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  content: "";
  transform: rotate(45deg);
  top: 100%;
  left: 50%;
  border-left-width: 0;
  border-top-width: 0;
  background-color: #010417;
}

.aboutsection1::before {
  position: absolute;
  z-index: 1;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  content: "";
  transform: rotate(45deg);
  top: 100%;
  left: 50%;
  border-left-width: 0;
  border-top-width: 0;
  background-color: #292929;
}

.servicesection1::before {
  position: absolute;
  z-index: 1;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  content: "";
  transform: rotate(45deg);
  top: 100%;
  left: 50%;
  border-left-width: 0;
  border-top-width: 0;
  background-color: #292929;
}

.servicegrid::before {
  position: absolute;
  z-index: 1;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  content: "";
  transform: rotate(45deg);
  top: 100%;
  left: 50%;
  border-left-width: 0;
  border-top-width: 0;
  background-color: #f0f7f7;
}

.servicegrid1::before {
  position: absolute;
  z-index: 1;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  content: "";
  transform: rotate(45deg);
  top: 100%;
  left: 50%;
  border-left-width: 0;
  border-top-width: 0;
  background-color: #dfeeee;
}